<template>
  <div class="container">
    <div class="content">
      <div class="title">{{ message.title }}</div>
      <div class="desc">
        <div class="desc_tip" v-for="(item, index) in message.tip" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="image">
        <img :src="message.img" alt="" />
      </div>
      <div class="desc_word">{{ message.desc }}</div>
      <!-- 相关内容 -->
      <div class="Related">
        <h2>Related Posts</h2>
        <li>走出暴力阴影</li>
        <li>蕾丝的联想</li>
      </div>
    </div>

    <div class="updateEssay">
      <div class="essayTitle">最新文章</div>
      <div class="news" v-for="(essay, index) in essayList" :key="index">
        <div class="new">{{ essay }}</div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: [],
      essayList: [
        "妈不得不说的话",
        "走出暴力阴影",
        "跨文化婚姻辅导",
        "蕾丝的联想",
        "什么是婚姻中的财务虐待",
      ],
    };
  },
  created() {
    this.message = this.$route.params.items;
  },
  methods: {},
};
</script>
<style  lang='less' scoped>
.container {
  .Related {
    margin: 20px 0 80px 0;
    width: 100vw;
    text-align: left;
    li {
      cursor: pointer;
      color: #5499c7;
    }
  }
  display: flex;
  flex-wrap: wrap;
  .updateEssay {
    margin: 0px 0 0 50px;
    width: 220px;
    height: 240px;
    font-size: 18px;
    font-weight: 550;
    display: flex;
    flex-direction: column;
    .essayTitle {
      text-align: left;
      margin-bottom: 5px;
    }
    .new {
      margin-bottom: 10px;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
    .new:hover {
      cursor: pointer;
      color: #5499c7;
    }
  }
}
.footer {
  width: 100vw;
  height: 100px;
  background-color: #5499c7;
}
.content {
  width: 60vw;
  border-right: 1px solid #e5e5e5;
  padding: 40px 0 0 15vw;
  text-align: left;
  .title {
    font-size: 30px;
    font-weight: 550;
  }
  .desc {
    display: flex;
  }
  .desc_tip:nth-child(1) {
    margin-right: 10px;
  }
  .desc_tip {
    color: #666;
    font-size: 16px;
  }
  .desc_word {
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }
  .image {
    margin: 20px 0;
    width: 30vw;
    height: 20vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
@media screen and (max-width: 1140px) {
  .content {
    width: 100vw;
    padding-left: 10vw !important;
  }
  .Related {
    width: 100vw;
    // padding-left: 5vw !important;
    text-align: left;
  }
  .updateEssay {
    margin: 0 !important;
    padding-left: 5vw !important;
  }
  .image {
    width: 80vw !important;
    height: 50vw !important;
  }
}
</style>